<template>
  <div>
    <section class="masthead text-center">
      <div class="container">
        <h3>People</h3>
        <p class="text-padd">With certified FinOps practitioners in 5 countries across three continents, Efficient Cloud is a leading boutique FinOps consultancy. We draw on a rich history of building world-class social apps, supplemented with deep cloud knowledge and cutting-edge AI.</p>
      </div>
    </section>

    <section class="section section-gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <div class="phase-image">
              <img src="images/people-quote.png" alt="quote" />
            </div>
          </div>
          <div class="col-lg-7">
            <div class="quote-block">
              <span class="quote-icon"><img src="images/quote.svg" alt="quote" /></span>
              <p class="quote">“We were one of the first adopters of cloud technologies in Asia Pacific, and deployed apps used by over 100 million people on AWS and Google Cloud in the late 2000s, including social apps for Starbucks, Disney, and Coca-Cola. Our expertise at cloud cost optimisation was widely recognised, and we were featured as an AWS case study in 2014.“</p>
              <div class="quote-author">
                <p class="author">Efficient Cloud</p>
                <p>COO</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section finops-block">
      <div class="container">
        <div class="text-center">
          <img src="images/finops-certified-logo.png" alt="" />
          <h3>Certified <span class="text-primary">FinOps Practitioners</span></h3>
          <p>We have eight staff members, from the CEO to the COO, CTO, product manager and engineers that are FinOps certified and ready to serve you.  We are one of a very elite number of companies in the world  (less than ten) to have this many FinOps certified practitioners.</p>
        </div>
        <!--<div class="finops-carousel owl-carousel owl-theme">
          <div class="item">
            <div class="certified-member">
              <div class="avatar"><img src="images/member/1.jpg" alt="Janakan"></div>
              <div class="certified-content">
                <h4>Janakan Arulkumarasan</h4>
                <div class="member-profile">
                  <span>CEO</span>
                  <span>|</span>
                  <a href="https://www.linkedin.com/in/arulkumarasan" target="_blank"><img src="images/cs-icons-linkedin-filled.png"></a>
                </div>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="certified-member">
              <div class="avatar"><img src="images/member/2.jpg" alt="Rajiv"></div>
              <div class="certified-content">
                <h4>Rajiv Khatri</h4>
                <div class="member-profile">
                  <span>COO</span>
                  <span>|</span>
                  <a href="https://www.linkedin.com/in/rajivkhatri1/" target="_blank"><img src="images/cs-icons-linkedin-filled.png"></a>
                </div>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="certified-member">
              <div class="avatar"><img src="images/member/3.jpg" alt="David"></div>
              <div class="certified-content">
                <h4>David Del Favero</h4>
                <div class="member-profile">
                  <span>CTO</span>
                  <span>|</span>
                  <a href="https://www.linkedin.com/in/david-del-favero-5b942132" target="_blank"><img src="images/cs-icons-linkedin-filled.png"></a>
                </div>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="certified-member">
              <div class="avatar"><img src="images/member/4.jpg" alt="Priyanka"></div>
              <div class="certified-content">
                <h4>Priyanka Solanki</h4>
                <div class="member-profile">
                  <span>Engineer</span>
                  <span>|</span>
                  <a href="https://www.linkedin.com/in/priyanka-solanki/" target="_blank"><img src="images/cs-icons-linkedin-filled.png"></a>
                </div>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="certified-member">
              <div class="avatar"><img src="images/member/5.jpg" alt="Kanishka"></div>
              <div class="certified-content">
                <h4>Kanishka Joshi</h4>
                <div class="member-profile">
                  <span>Engineer</span>
                  <span>|</span>
                  <a href="https://www.linkedin.com/in/kanishka-joshi-1abb63a1" target="_blank"><img src="images/cs-icons-linkedin-filled.png"></a>
                </div>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="certified-member">
              <div class="avatar"><img src="images/member/6.jpg" alt="Aastha Panta"></div>
              <div class="certified-content">
                <h4>Aastha Panta</h4>
                <div class="member-profile">
                  <span>Engineer</span>
                  <span>|</span>
                  <a href="https://www.linkedin.com/in/aastha-panta-a7b599128/" target="_blank"><img src="images/cs-icons-linkedin-filled.png"></a>
                </div>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="certified-member">
              <div class="avatar"><img src="images/member/7.jpg" alt="Madhurjya"></div>
              <div class="certified-content">
                <h4>Madhurjya Bora</h4>
                <div class="member-profile">
                  <span>Engineer</span>
                  <span>|</span>
                  <a href="https://www.linkedin.com/in/madhurjya-bora-51a35a163/" target="_blank"><img src="images/cs-icons-linkedin-filled.png"></a>
                </div>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="certified-member">
              <div class="avatar"><img src="images/member/8.jpg" alt="Saumya"></div>
              <div class="certified-content">
                <h4>Saumya Ahuja</h4>
                <div class="member-profile">
                  <span>Product</span>
                  <span>|</span>
                  <a href="https://sg.linkedin.com/in/saumyahuja" target="_blank"><img src="images/cs-icons-linkedin-filled.png"></a>
                </div>
              </div>
            </div>
          </div>
        </div>-->
      </div>
    </section>

    <section class="section section-gray">
      <div class="container">
        <h3 class="text-center">Our History</h3>

        <ul class="timeline">
          <li class="timeline-inverted">
            <div class="timeline-badge">
              <span></span>
            </div>
            <div class="timeline-year">
              <h4>2006-2009</h4>
            </div>
            <div class="timeline-panel">
              <h4 class="timeline-title">The Early Years (2006 - 2009)</h4>
              <p class="timeline-body">C-Suite Labs (formerly known as "Onoko") was founded in 2006. The initial years provided valuable experience as we built a number of social apps.</p>
            </div>
          </li>

          <li>
            <div class="timeline-badge">
              <span></span>
            </div>
            <div class="timeline-year">
              <h4>2009-2015</h4>
            </div>
            <div class="timeline-panel">
              <h4 class="timeline-title">The Social Years (2009 - 2015)</h4>
              <p class="timeline-body">We decided to divest our social assets, and focus on building Oikos, a new social platform for small communities. During this time, we developed a deep understanding of Cloud technologies, and B2B marketing - skills which have served us well. We continue to maintain Oikos for existing customers.</p>
            </div>
          </li>

          <li class="timeline-inverted">
            <div class="timeline-badge">
              <span></span>
            </div>
            <div class="timeline-year">
              <h4>2016-2018</h4>
            </div>
            <div class="timeline-panel">
              <h4 class="timeline-title">The Community Years (2016 - 2018)</h4>
              <p class="timeline-body">In 2015, we decided to divest our social assets, and focus on building Oikos, a new social platform for small communities. During this time, we developed a deep understanding of Cloud technologies, and B2B marketing - skills which have served us well. We continue to maintain Oikos for existing customers.</p>
            </div>
          </li>

          <li>
            <div class="timeline-badge">
              <span></span>
            </div>
            <div class="timeline-year">
              <h4>2019 - Now</h4>
            </div>
            <div class="timeline-panel">
              <h4 class="timeline-title">The Efficient Cloud Years (2019 - now)</h4>
              <p class="timeline-body">Since 2018, we have continued to hone our cloud optimization offering to the point where today we are savings our 200+ clients an average of 40% on their cloud costs... And we're just getting started.</p>
            </div>
          </li>
        </ul>

      </div>
    </section>

    <section class="work-with-us">
      <div class="work-inner">
        <div class="container">
          <h3>Work With Us</h3>
          <p>Keen to work on the cutting edge of cloud managed services and cloud cost optimization? We welcome applications from talented cloud architects, data scientists, developers, product/project managers, and community builders across the world.</p>
          <a href="https://careers.csuitecircle.com" class="btn btn-default">See Opportunities</a>
        </div>
      </div>
    </section>

    <section class="cta">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h4>Let's get started</h4>
            <p>Chat with one of our cloud cost specialists to learn more.</p>
          </div>
          <div class="col-lg-5">
            <div class="text-lg-right">
              <a href="https://csuitecircle.typeform.com/to/NjV4hDJz" target="_blank" class="btn btn-default">Get Started</a>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>
<script>
export default {
  mounted() {
    window.setupOwl();
  },
};
</script>
