<template>
  <div>
    <section class="masthead text-center">
      <div class="container">
        <h3>Platform</h3>
        <p class="text-padd">The Efficient Cloud Platform offers managed cloud services that are 50% cheaper than the market leader. We achieve these savings by combining our FinOps expertise, with our ability to bulk purchase capacity, and negotiate better cloud pricing on behalf of all our customers.</p>
      </div>
    </section>

    <section class="section section-gray">
      <div class="container">
        <div class="phase-title">
          <h3>Managed Cloud Services</h3>
          <p>Compatible with AWS, Azure, Google Cloud, Oracle Cloud, and more.</p>
        </div>

        <div class="row">
          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/platform/static-site.svg" alt="Static Site" />
                </div>
                <h4 class="card-title">Static Site</h4>
                <p>Efficiently deliver static HTML + javascript sites, with built-in git triggers, builds, SSL certificates, and usage monitoring.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/platform/cdn.svg" alt="Content Delivery Network (CDN)" />
                </div>
                <h4 class="card-title">Content Delivery (CDN)</h4>
                <p>Efficiently deliver images, video and other data to users quickly and cost-effectively.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/platform/batch.svg" alt="Batch Jobs" />
                </div>
                <h4 class="card-title">Batch Jobs</h4>
                <p>Efficiently run one-off containerized workloads using Kubernetes on any supported cloud.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/platform/containerized.svg" alt="Containerized Stateless Services" />
                </div>
                <h4 class="card-title">Stateless Services</h4>
                <p>Efficiently run long-running containerized services (like APIs) using Kubernetes on any supported cloud.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/platform/transcoding.svg" alt="Transcoding" />
                </div>
                <h4 class="card-title">Transcoding</h4>
                <p>Efficiently convert audio / video files from one format to another.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/platform/caption.svg" alt="Captioning" />
                </div>
                <h4 class="card-title">Captioning</h4>
                <p>Efficiently generate captions from an audio or video file.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/platform/file-conversion.svg" alt="File Conversion" />
                </div>
                <h4 class="card-title">File Conversion</h4>
                <p>Efficiently convert your documents, images, presentations and spreadsheets from one file type to another.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/platform/object-detection.svg" alt="Object Detection" />
                </div>
                <h4 class="card-title">Object Detection</h4>
                <p>Efficiently detect objects present in your images or videos.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/platform/character.svg" alt="Optical Character Recognition" />
                </div>
                <h4 class="card-title">Text Recognition</h4>
                <p>Efficiently extract text from your images or videos.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/platform/content-moderation.svg" alt="Content Moderation" />
                </div>
                <h4 class="card-title">Content Moderation</h4>
                <p>Efficiently filter your images or videos for inappropriate content.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/platform/tagging.svg" alt="Tagging" />
                </div>
                <h4 class="card-title">Tagging</h4>
                <p>Efficiently generate tags for your images or videos.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/platform/media.svg" alt="Media Library" />
                </div>
                <h4 class="card-title">Media Library</h4>
                <p>Efficiently store all your media assets - documents, audio, images, video and search through them intelligently.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/platform/sql.svg" alt="PostgreSQL" />
                </div>
                <h4 class="card-title">PostgreSQL</h4>
                <p>Efficiently run your PostgreSQL database with built-in monitoring, backup and recovery.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/platform/redis.svg" alt="Redis" />
                </div>
                <h4 class="card-title">Redis</h4>
                <p>Efficiently run your Redis database with built-in monitoring, backup and recovery.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/platform/mongodb.svg" alt="MongoDB" />
                </div>
                <h4 class="card-title">MongoDB</h4>
                <p>Efficiently run your MongoDB database with built-in monitoring, backup and recovery.</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <app-cta-next label="Meet Our People" next="People" />

  </div>
</template>
<script>
import AppCtaNext from '@/components/general/CtaNext.vue';

export default {
  components: {
    AppCtaNext,
  },
};
</script>
