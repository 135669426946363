<template id="">
  <div>

    <section class="hero-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <div class="hero-content">
              <h1><span class="welcome"><img src="images/hand.svg" alt="welcome"></span> <span class="nowrap">We are Lasting City,</span></h1>
              <h2>The US sales representative for Efficient Cloud.</h2>
              <h3>Efficient Cloud helps dev teams reduce cloud waste.</h3>
              <div class="button-group">
                <a href="https://csuitecircle.typeform.com/to/NjV4hDJz" target="_blank" class="btn btn-default">Get Started</a>
                <router-link :to="{name:'Problem'}" class="btn btn-outline-white">Learn More</router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-5 d-lg-block d-none">
            <div class="hero-image">
              <img src="images/hero-cloud.svg" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="overlay"><img src="images/hero-bg.svg" alt="" /></div>
    </section>

    <section class="section text-center">
      <div class="container">
        <h3>What’s the <span class="text-primary">Problem?</span></h3>
        <p class="text-padd">30% of all cloud spend is waste. Globally, that’s over $100 billion a year wasted on unused, underused, or overpriced cloud services. Cloud waste is bad for everyone - businesses, investors, employees - even the planet.</p>
        <router-link :to="{name:'Problem'}" class="btn btn-outline-primary">Learn More</router-link>
      </div>
    </section>

    <section class="section section-gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="getsbad-content">
              <h3>How did things <span class="text-primary">get so bad?</span></h3>
              <p>In the move from on-prem to cloud, most organizations see a huge increase in innovation and agility. However, many also see a decline in cost visibility and efficiency.</p>
              <router-link :to="{name:'Problem'}" class="btn btn-primary btn-desktop">Learn More</router-link>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="card-block">
              <div class="card">
                <h4>On Prem</h4>
                <p>In an on-prem world, server spend is largely controlled by finance.</p>
                <p>To provision new capacity, engineering teams must convince finance, then wait for ops to purchase and install new capacity.</p>
                <p>This can take weeks. It’s great for cost control, but terrible for innovation and agility.</p>
              </div>
              <div class="verses">VS</div>
              <div class="card">
                <h4>Cloud</h4>
                <p>In a cloud world, server spend is largely controlled by engineering.</p>
                <p>Anyone from a junior developer to a CTO can (intentionally or accidentally) provision hundreds of machines in seconds.</p>
                <p>This is great for agility, but terrible for cost visibility and control.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-mob">
          <router-link :to="{name:'Problem'}" href="#" class="btn btn-primary">Learn More</router-link>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <h3 class="text-center">How can <span class="text-primary">Efficient Cloud help?</span></h3>
        <p class="text-padd text-center">Efficient Cloud uses a combination of artificial intelligence and human expertise to help clients reduce cloud waste. Built around the industry-leading FinOps Framework, our approach follows a cycle of Inform → Optimize → Operate.</p>

        <div class="help-pointers">
          <div class="row">
            <div class="col-lg-4">
              <div class="help-block">
                <div class="icon-container">
                  <img src="images/help-inform.svg" alt="inform" />
                </div>
                <div class="help-card">
                  <p>In the Inform phase, we help clients understand their cloud spend.</p>
                  <ul class="dot">
                    <li>Consolidate data from multiple cloud accounts.</li>
                    <li>Design and enforce tagging policies to improve visibility.</li>
                    <li>Use show-back or charge-back to help teams understand their spend.</li>
                    <li>Set KPIs, budgets, and forecasts to track our performance over time.</li>
                  </ul>
                  <router-link :to="{name:'Process'}" class="btn btn-clear">Learn more <img src="images/cs-icons-arrow.svg" alt="" /></router-link>
                </div>
              </div>

            </div>
            <div class="col-lg-4">
              <div class="help-block">
                <div class="icon-container">
                  <img src="images/help-optimize.svg" alt="optimize" />
                </div>
                <div class="help-card">
                  <p>In the Optimize phase, we help clients reduce cloud costs.</p>
                  <ul class="dot">
                    <li>Identify and remove unused services.</li>
                    <li>Use automation to right-size underused services.</li>
                    <li>Move services to cheaper regions, instance families, or providers.</li>
                    <li>Intelligently enter into reserved and committed use discounts.</li>
                    <li>Identify and remove unused services.</li>
                  </ul>
                  <router-link :to="{name:'Process'}" class="btn btn-clear">Learn more <img src="images/cs-icons-arrow.svg" alt="" /></router-link>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="help-block">
                <div class="icon-container">
                  <img src="images/help-operate.svg" alt="operate" />
                </div>
                <div class="help-card">
                  <p>In the Operate phase, we help clients adopt sustainable practices.</p>
                  <ul class="dot">
                    <li>Train stakeholders to establish a common language and approach.</li>
                    <li>Use automation to right-size underused services.</li>
                    <li>Establish a Cloud Cost Center of Excellence, involving business, financial, and operational stakeholders.</li>
                    <li>Establish a regular cadence of review and optimization.</li>
                  </ul>
                  <router-link :to="{name:'Process'}" class="btn btn-clear">Learn more <img src="images/cs-icons-arrow.svg" alt="" /></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section section-gray unique-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-xl-5">
            <h3>What make <span class="text-primary">Efficient Cloud unique?</span></h3>
            <div class="unique-img">
              <img src="images/unique.svg" alt="" />
            </div>
          </div>
          <div class="col-lg-8 col-xl-7">
            <div class="unique-container">
              <div class="unique-block">
                <div class="card">
                  <h4>Our Process</h4>
                  <p>No two clients are the same. Built on the industry-leading FinOps Framework, we tailor our approach for the needs of each individual client, balancing the need for quick wins with the need for long-term buy-in and governance.</p>
                  <router-link :to="{name:'Process'}" class="btn btn-clear">Learn more <img src="images/cs-icons-arrow.svg" alt="" /></router-link>
                </div>
                <div class="card">
                  <h4>Our Platform</h4>
                  <p>All Efficient Cloud clients benefit from free access to our Cloud Cost Management tooling - technology that normally costs tens of thousands of dollars each month. In addition, Efficient Cloud clients are able to use our Efficient Cloud Platform, ultra-efficient cloud PaaS services that are typically 50% cheaper than the market leader.</p>
                  <router-link :to="{name:'Platform'}" class="btn btn-clear">Learn more <img src="images/cs-icons-arrow.svg" alt="" /></router-link>
                </div>
              </div>
              <div class="unique-block">
                <div class="card">
                  <h4>Our History</h4>
                  <p>Before we started Efficient Cloud, we spent 15 years building chart-topping apps used by over 100 million people worldwide. To survive, we had to keep our cloud costs low - a feat that made AWS feature us as a case study in cost excellence in 2010.</p>
                  <router-link :to="{name:'People'}" class="btn btn-clear">Learn more <img src="images/cs-icons-arrow.svg" alt="" /></router-link>
                </div>

                <div class="card">
                  <h4>Our Team</h4>
                  <p>Our team has FinOps Certified cloud practitioners in 5 countries across 3 continents, making us one of the top 10 cloud cost consulting houses globally (by number of certified practitioners).</p>
                  <router-link :to="{name:'People'}" class="btn btn-clear">Learn more <img src="images/cs-icons-arrow.svg" alt="" /></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section finops-block">
      <div class="container">
        <div class="text-center">
          <img src="images/finops-certified-logo.png" alt="" />
          <h3>Certified <span class="text-primary">FinOps Practitioners</span></h3>
        </div>

        <div class="finops-carousel owl-carousel owl-theme">
          <div class="item">
            <div class="certified-member">
              <div class="avatar"><img src="images/member/1.jpg" alt="Janakan"></div>
              <div class="certified-content">
                <h4>Janakan Arulkumarasan</h4>
                <div class="member-profile">
                  <span>CEO</span>
                  <span>|</span>
                  <a href="https://www.linkedin.com/in/arulkumarasan" target="_blank"><img src="images/cs-icons-linkedin-filled.png"></a>
                </div>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="certified-member">
              <div class="avatar"><img src="images/member/2.jpg" alt="Rajiv"></div>
              <div class="certified-content">
                <h4>Rajiv Khatri</h4>
                <div class="member-profile">
                  <span>COO</span>
                  <span>|</span>
                  <a href="https://www.linkedin.com/in/rajivkhatri1/" target="_blank"><img src="images/cs-icons-linkedin-filled.png"></a>
                </div>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="certified-member">
              <div class="avatar"><img src="images/member/3.jpg" alt="David"></div>
              <div class="certified-content">
                <h4>David Del Favero</h4>
                <div class="member-profile">
                  <span>CTO</span>
                  <span>|</span>
                  <a href="https://www.linkedin.com/in/david-del-favero-5b942132" target="_blank"><img src="images/cs-icons-linkedin-filled.png"></a>
                </div>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="certified-member">
              <div class="avatar"><img src="images/member/4.jpg" alt="Priyanka"></div>
              <div class="certified-content">
                <h4>Priyanka Solanki</h4>
                <div class="member-profile">
                  <span>Engineer</span>
                  <span>|</span>
                  <a href="https://www.linkedin.com/in/priyanka-solanki/" target="_blank"><img src="images/cs-icons-linkedin-filled.png"></a>
                </div>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="certified-member">
              <div class="avatar"><img src="images/member/5.jpg" alt="Kanishka"></div>
              <div class="certified-content">
                <h4>Kanishka Joshi</h4>
                <div class="member-profile">
                  <span>Engineer</span>
                  <span>|</span>
                  <a href="https://www.linkedin.com/in/kanishka-joshi-1abb63a1" target="_blank"><img src="images/cs-icons-linkedin-filled.png"></a>
                </div>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="certified-member">
              <div class="avatar"><img src="images/member/6.jpg" alt="Aastha Panta"></div>
              <div class="certified-content">
                <h4>Aastha Panta</h4>
                <div class="member-profile">
                  <span>Engineer</span>
                  <span>|</span>
                  <a href="https://www.linkedin.com/in/aastha-panta-a7b599128/" target="_blank"><img src="images/cs-icons-linkedin-filled.png"></a>
                </div>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="certified-member">
              <div class="avatar"><img src="images/member/7.jpg" alt="Madhurjya"></div>
              <div class="certified-content">
                <h4>Madhurjya Bora</h4>
                <div class="member-profile">
                  <span>Engineer</span>
                  <span>|</span>
                  <a href="https://www.linkedin.com/in/madhurjya-bora-51a35a163/" target="_blank"><img src="images/cs-icons-linkedin-filled.png"></a>
                </div>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="certified-member">
              <div class="avatar"><img src="images/member/8.jpg" alt="Saumya"></div>
              <div class="certified-content">
                <h4>Saumya Ahuja</h4>
                <div class="member-profile">
                  <span>Engineer</span>
                  <span>|</span>
                  <a href="https://sg.linkedin.com/in/saumyahuja" target="_blank"><img src="images/cs-icons-linkedin-filled.png"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section pricing-grid">
      <div class="container">
        <h3 class="text-center">How much does <span class="text-primary">Efficient Cloud cost?</span></h3>
        <p class="text-padd text-center">Efficient Cloud pays for itself, with fees from 3% of annual cloud spend, and guaranteed savings of at least 20% within 6 months.</p>
        <div class="ec-pricing-table">
          <div class="table-responsive ec-table">
            <table class="table">
              <tr class="borderless">
                <th class="headcol blank"></th>
                <td class="colhead chead1"><h4>Basic</h4><p>Up to $1m in annual cloud spend.</p></td>
                <td class="colhead chead2"><h4>Pro</h4><p>$1m - $10m in annual cloud spend.</p></td>
                <td class="colhead chead3"><h4>Enterprise</h4><p>More than $10m a year on cloud.</p></td>
              </tr>
              <tr>
                <th class="headcol">Pricing</th>
                <td class="col">5% of cloud spend</td>
                <td class="col">4% of cloud spend</td>
                <td class="col">3% of cloud spend</td>
              </tr>
              <tr>
                <th class="headcol">Consultant Time</th>
                <td class="col">10+ hours of consultant time each month.</td>
                <td class="col">100+ hours of consultant time each month.</td>
                <td class="col">500+ hours of consultant time each month.</td>
              </tr>
              <tr>
                <th class="headcol">Platform Access</th>
                <td class="col">Unlimited.</td>
                <td class="col">Unlimited.</td>
                <td class="col">Unlimited.</td>
              </tr>
              <tr>
                <th class="headcol">Staff Training</th>
                <td class="col">Available for a fee.</td>
                <td class="col">Included.</td>
                <td class="col">Included.</td>
              </tr>
              <tr>
                <th class="headcol">Savings Guarantee</th>
                <td class="col"><p>20% savings within 6 months.</p> <a href="https://csuitecircle.typeform.com/to/NjV4hDJz" target="_blank" class="btn btn-primary">Get Started</a></td>
                <td class="col"><p>20% savings within 6 months</p> <a href="https://csuitecircle.typeform.com/to/NjV4hDJz" target="_blank" class="btn btn-primary">Get Started</a></td>
                <td class="col"><p>20% savings within 6 months.</p> <a href="https://csuitecircle.typeform.com/to/NjV4hDJz" target="_blank" class="btn btn-primary">Get Started</a></td>
              </tr>
            </table>
            <div class="text-padd text-center mt-5">
              <h4>❤️ Not for Profit?</h4>
              <p>We offer our services pro-bono to a limited number of non-profits each year. <a href="mailto:janakan@efficientcloud.com">Contact us</a> to learn more.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cta">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h4>Let's get started</h4>
            <p>Chat with one of our cloud cost specialists to learn more.</p>
          </div>
          <div class="col-lg-5">
            <div class="text-lg-right">
              <a href="https://csuitecircle.typeform.com/to/NjV4hDJz" target="_blank" class="btn btn-default">Get Started</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  mounted() {
    window.setupOwl();
  },
};
</script>
