<template>
  <div>
    <section class="masthead text-center">
      <div class="container">
        <h3>Process</h3>
        <p class="text-padd">Efficient Cloud uses a combination of artificial intelligence and human expertise to help clients reduce cloud waste. Built around the industry-leading FinOps Framework, our process follows a cycle of Inform → Optimize → Operate, helping our clients grow their capabilities and attain greater cloud cost maturity.</p>
      </div>
    </section>

    <section class="section section-gray">
      <div class="container">
        <div class="phase-title text-primary">
          <h3>Phases</h3>
          <p>The FinOps lifecycle consists of three phases that are continuously repeated.</p>
        </div>
        <div class="row reversed-row">
          <div class="col-lg-6">
            <div class="phase-block">
              <div class="block-item">
                <h4>Inform</h4>
                <p>Gain the visibility you need for cloud spend allocation. This helps create shared accountability within your business by showing teams what they are spending and why.</p>
              </div>

              <div class="block-item">
                <h4>Optimize</h4>
                <p>Empower your teams by providing actionable recommendations.</p>
              </div>

              <div class="block-item">
                <h4>Operate</h4>
                <p>Build governance policies that provide continuity and structure. This enables the goals of Technology, Finance and Business to be jointly achieved.</p>
              </div>

            </div>
          </div>
          <div class="col-lg-6">
            <div class="phase-image">
              <img src="images/process/graph.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row reversed-row">
          <div class="col-lg-7">
            <div class="content-block">
              <h3><span class="count">1</span>Inform Phase</h3>
              <p>In the inform phase, we work with clients to gain a comprehensive understanding of cloud spend, and to equip everyone - from individual development teams to the CEO - with real-time, comprehensive, and accurate data dashboards that are essential for intelligent decision making.</p>

              <p>During this phase, we will work with you to:</p>

              <ul class="dot">
                <li>Consolidate spend and consumption data from across multiple clouds or cloud accounts.</li>
                <li>Determine and enforce tagging strategies.</li>
                <li>Implement show-back or charge-back reports, so each team or business unit is fully aware of their costs.</li>
                <li>Allocate shared spend and past commitments to ensure costs are “fully loaded”.</li>
                <li>Develop meaningful metrics that align with business goals.</li>
                <li>Measure benchmarks within teams, and against similar organisations.</li>
                <li>Build real-time alerts to avoid run-away spend.</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="phase-image">
              <img src="images/process/inform.png" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section section-gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <div class="phase-image">
              <img src="images/process/optimise.png" alt=""/>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="content-block">
              <h3><span class="count">2</span>Optimise Phase</h3>
              <p>In the optimise phase, we take the insights garnered in the inform phase, and begin reducing spend.</p>
              <p>In this phase, we will work with you to:</p>
              <ul class="dot">
                <li>Eliminate unused resources.</li>
                <li>Rightsize over-provisioned resources.</li>
                <li>Replace expensive managed services with competitive alternatives.</li>
                <li>Buy and sell reserved or committed-use discounts with confidence, maximising coverage while minimising risk and unused commitments.</li>
                <li>Turning off non-production resources when not in use.</li>
                <li>Refactor services towards more efficient cloud-native alternatives.</li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row reversed-row">
          <div class="col-lg-7">
            <div class="content-block">
              <h3><span class="count">3</span>Operate Phase</h3>
              <p>In the operate phase, we seek to lock-in the efficiencies gained by driving cultural change.</p>
              <p>In this phase, we will work with you to:</p>
              <ul class="dot">
                <li>Develop an in-house “Cloud Center of Excellence” including business, financial, and tech stakeholders to drive long-term efficiencies.</li>
                <li>Equip and train staff with the tools needed for long-term success.</li>
                <li>Measure performance against key business objectives.</li>
                <li>Determine and implement key governance policies and models.</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="phase-image">
              <img src="images/process/operate.png" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cta">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h4>Let's get started</h4>
            <p>Chat with one of our cloud cost specialists to learn more.</p>
          </div>
          <div class="col-lg-5">
            <div class="text-lg-right">
              <a href="https://csuitecircle.typeform.com/to/NjV4hDJz" target="_blank" class="btn btn-default">Get Started</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section section-gray">
      <div class="container">
        <div class="phase-title">
          <h3>Capabilities</h3>
          <p>As we cycle through the FinOps Phases, we measure and build client capabilities in the following areas.</p>
        </div>

        <div class="row">
          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/process/icon/idea.svg" alt="" />
                </div>
                <h4 class="card-title">Understanding Cloud Usage and Cost</h4>
                <ul class="dot">
                  <li>Cost Allocation (Metadata & Hierarchy)</li>
                  <li>Data Analysis and Showback</li>
                  <li>Managing Shared Cost</li>
                  <li>Data Ingestion & Normalization</li>
                  <li>Managing Anomalies</li>
                  <li>Forecasting</li>
                  <li>Measuring Unit Costs</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/process/icon/tracking.svg" alt="" />
                </div>
                <h4 class="card-title">Performance Tracking & Benchmarking</h4>
                <ul class="dot">
                  <li>Measuring Unit Costs</li>
                  <li>Managing Commitment Based Discounts</li>
                  <li>Resource Utilization & Efficiency</li>
                  <li>Forecasting</li>
                  <li>Budget Management</li>
                  <li>Managing Anomalies</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/process/icon/organizational.svg" alt="" />
                </div>
                <h4 class="card-title">Organizational Alignment</h4>
                <ul class="dot">
                  <li>Establishing FinOps Culture</li>
                  <li>Managing Shared Cost</li>
                  <li>Chargeback & IT Finance Integration</li>
                  <li>Data Analysis and Showback</li>
                  <li>Budget Management</li>
                  <li>FinOps Education & Enablement</li>
                  <li>Establishing a FinOps Decision & Accountability Structure</li>
                  <li>Cloud Policy & Governance</li>
                  <li>IT Asset Management Integration</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/process/icon/decision.svg" alt="" />
                </div>
                <h4 class="card-title">Real-Time Decision Making</h4>
                <ul class="dot">
                  <li>Managing Anomalies</li>
                  <li>Establishing a FinOps Decision & Accountability Structure</li>
                  <li>Measuring Unit Costs</li>
                  <li>Data Analysis and Showback</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/process/icon/optimization.svg" alt="" />
                </div>
                <h4 class="card-title">Cloud Rate Optimization</h4>
                <ul class="dot">
                  <li>Data Analysis and Showback</li>
                  <li>Managing Commitment Based Discounts</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="feature-card">
                <div class="card-icon">
                  <img src="images/process/icon/usage.svg" alt="" />
                </div>
                <h4 class="card-title">Cloud Usage Optimization</h4>
                <ul class="dot">
                  <li>Data Analysis and Showback</li>
                  <li>Onboarding Workloads</li>
                  <li>Resource Utilization & Efficiency</li>
                  <li>Workload Management & Automation</li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="phase-title">
          <h3>Maturity</h3>
          <p class="text-padd">As clients cycle through phases and build capabilities, we aim to see a gradual growth in maturity across the organization. This happens gradually, and may happen faster in some teams than others.</p>
        </div>

        <div class="row">

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="maturity-card">
                <div class="card-icon">
                  <img src="images/process/icon/crawl.svg" alt="">
                </div>
                <h4 class="card-title">Crawl</h4>
                <p>Organisations at the Crawl stage are just beginning their FinOps journey.</p>
                <p>Organisations at this stage are characterised by:</p>
                <ul class="dot">
                  <li>Little reporting or tooling</li>
                  <li>Basic KPIs</li>
                  <li>Plans to address “low hanging fruit”</li>
                  <li>A low level of organisational buy-in or understanding of the FinOps process.</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="maturity-card">
                <div class="card-icon">
                  <img src="images/process/icon/walk.svg" alt="">
                </div>
                <h4 class="card-title">Walk</h4>
                <p>Organisations at the Walk stage are making solid progress on their FinOps journey.</p>
                <p>Organisations at this stage are characterised by:</p>
                <ul class="dot">
                  <li>Broad understanding or buy-in across the organisation.</li>
                  <li>Automation and/or processes cover most  requirements.</li>
                  <li>Difficult edge cases are identified, and intentionally deferred or rejected.</li>
                  <li>Advanced KPIs set that align with organization goals.</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4">
            <div class="feature-card-block">
              <div class="maturity-card">
                <div class="card-icon">
                  <img src="images/process/icon/run.svg" alt="">
                </div>
                <h4 class="card-title">Run</h4>
                <p>Organisations at the Run stage are excelling in their FinOps journey.</p>
                <p>Organisations at this stage are characterised by:</p>
                <ul class="dot">
                  <li>An established Cloud Center of Excellence moving at a consistent pace.</li>
                  <li>Efforts to address difficult edge cases.</li>
                  <li>Comprehensive adoption of automation.</li>
                  <li>Deep alignment of KPIs with organization goals.</li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <app-cta-next label="Discover Our Platform" next="Platform" />

  </div>
</template>
<script>
import AppCtaNext from '@/components/general/CtaNext.vue';

export default {
  components: {
    AppCtaNext,
  },
};
</script>
