<template>
  <header class="navbar ec-header navbar-dark navbar-expand-md" :class="headerClass">
    <div class="container">
      <a class="navbar-brand logo" href="index.html">
        <img class="d-lg-block" src="images/logo.svg" alt="Efficient Cloud" />
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="ml-auto navbar-nav">
          <li class="nav-item" v-for="link in navigationLinks" :key="link"
            :active="isActive(link)"
          >
            <router-link class="nav-link" :to="{name:link}">{{link}}</router-link>
          </li>
        </ul>
        <a href="https://csuitecircle.typeform.com/to/NjV4hDJz" target="_blank" class="btn btn-small btn-outline-white ml-2">Get Started</a>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  computed: {
    headerClass() {
      const isHomepage = (this.$route.name === 'Home');
      return isHomepage ? 'ec-fixed' : 'darkHeader';
    },
  },
  methods: {
    isActive(name) {
      return (name === this.$route.name);
    },
  },
  data() {
    return {
      navigationLinks: [
        'Home',
        'Problem',
        'Process',
        'Platform',
        'People',
      ],
    };
  },
};
</script>
