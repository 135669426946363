<template>
  <section class="cta cta-big text-center">
    <div class="container">
      <h3>Let's get started</h3>
      <p class="text-padd">Whatever cloud financial challenges you face, Efficient Cloud is here to help. Our certified specialists will work with you to identify and tackle waste in a sustainable cadence that leads to long-term efficiency within your team.</p>
      <div class="button-group">
        <router-link :to="{name: next}" class="btn btn-default">{{ label }}</router-link>
        <a href="https://csuitecircle.typeform.com/to/NjV4hDJz" target="_blank" class="btn btn-outline-white">Learn More</a>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Discover Our Process',
    },
    next: {
      type: String,
      default: 'Process',
    },
  },
};
</script>
