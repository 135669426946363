import Vue from 'vue';
import VueRouter from 'vue-router';
import Container from '@/views/Container.vue';
import Home from '@/views/Home.vue';
import People from '@/views/People.vue';
import Platform from '@/views/Platform.vue';
import Problem from '@/views/Problem.vue';
import Process from '@/views/Process.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Container',
    component: Container,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
      },
      {
        path: 'people',
        name: 'People',
        component: People,
      },
      {
        path: 'platform',
        name: 'Platform',
        component: Platform,
      },
      {
        path: 'problem',
        name: 'Problem',
        component: Problem,
      },
      {
        path: 'process',
        name: 'Process',
        component: Process,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    }
    window.scrollTo(0, 0);
    return { x: 0, y: 0 };
  },
});

export default router;
