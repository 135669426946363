<template>
  <div>
    <section class="masthead text-center">
      <div class="container">
        <h3>Problem</h3>
        <p class="text-padd">According to the Flexera State of the Cloud Report, 30% of all cloud spend is waste - funds spent on unused, underused, or overpriced cloud services. Globally, this works out as over $100 billion in cloud waste each year.</p>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row reversed-row">
          <div class="col-lg-7">
            <div class="content-block">
              <h3><span class="count">1</span>Decline in the role of finance</h3>
              <p>In the quest for agility, companies moving from on-prem to cloud-first deployments usually see a shift in control, from finance to engineering.</p>

              <p>In an on-prem environment, engineers looking to add capacity need to seek approval from Finance, assistance from Procurement, and support from Operations to make such a change.</p>
              <p>By contrast, in the cloud, engineers can provision hundreds of servers instantly, with little or no oversight from other departments. This has led to increased agility, but has reduced the ability of Finance to effectively control cloud spend.</p>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="image-block">
              <img src="images/problem/01.jpg" alt=""/>
              <span class="shadow"></span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section section-gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <div class="image-block">
              <img src="images/problem/02.jpg" alt=""/>
              <span class="shadow"></span>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="content-block">
              <h3><span class="count">2</span>Conflicting priorities</h3>
              <p>The adoption of cloud has meant that engineering teams now control their own cloud spending. However, engineering teams are often stretched for time, meaning cost control  slips below other priorities.</p>

              <p>For example, while engineering teams may be penalised for service outages, security leaks, or slow delivery, very few teams would be penalised for spending 50-100% too much on cloud. This problem is exacerbated by the fact that most engineering teams understand their cloud requirements better than anyone else, making oversight difficult.</p>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row reversed-row">
          <div class="col-lg-7">
            <div class="content-block">
              <h3><span class="count">3</span>Lack of cost transparency</h3>
              <p>Cloud bills are complicated. The standard monthly bill aggregates spend across multiple teams within an organization, making it too infrequent and high-level for meaningful insights or corrective action. However, the detailed “cost and usage” cost reports may include hundreds of millions of rows with tens of thousands of unique SKUs - impossibly large for human (or even Excel) processing.</p>

              <p>At core, this is an issue of transparency - ensuring that teams are aware of, and accountable for their spend, as quickly as possible. This isn’t easy, especially for organisations with multi-cloud deployments, cross-team dependencies, legacy systems, and poor tagging policies.</p>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="image-block">
              <img src="images/problem/03.jpg" alt=""/>
              <span class="shadow"></span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section section-gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <div class="image-block">
              <img src="images/problem/04.jpg" alt=""/>
              <span class="shadow"></span>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="content-block">
              <h3><span class="count">4</span>Legacy mindsets</h3>
              <p>In an on-prem world, server capacity is usually fixed and always-on - right-sizing services or turning them off entirely usually has a negligible impact on overall cost. The opposite is true in the cloud.</p>

              <p>A common area of waste is over-provisioned infrastructure - paying for resource capacity that is larger than needed. Our internal research suggests that 40% of most cloud workloads are sized at least one size larger than needed for their workloads. Reducing one size can save 50%; reducing two sizes can save 75% or more.</p>

              <p>Another common area of waste is leaving development environments running 24/7. Over 40% of cloud spend is for non-production resources - but most production resources are only used during the 40-hour work week (if that), and are not needed the other 128 hours. That’s 76% waste.</p>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row reversed-row">
          <div class="col-lg-7">
            <div class="content-block">
              <h3><span class="count">5</span>Walled gardens</h3>
              <p>Every cloud provider engineers configures pricing to force developers to build exclusively on their platform. Nowhere is this more evident than in the stark difference in cost between ingress (data entering a cloud - usually free) and egress (data leaving a cloud - usually expensive). This is an anti-competitive “tax” on a multi-cloud approach.</p>

              <p>However, in many cases, engineering teams can reduce spend by embracing a multi-vendor approach. In some cases, this means deploying workloads to multiple clouds; in some cases it means using multiple vendors within a single cloud, especially for managed services like databases, AI, and media services.</p>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="image-block">
              <img src="images/problem/05.jpg" alt=""/>
              <span class="shadow"></span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section section-gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <div class="image-block">
              <img src="images/problem/06.jpg" alt=""/>
              <span class="shadow"></span>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="content-block">
              <h3><span class="count">6</span>Fear of commitment</h3>
              <p>Most cloud providers offer discounts of up to 60% in return for long-term commitments. While attractive, long-term commitments can be intimidating for startups and enterprises that are new to the cloud. These fears are not unfounded - premature commitments to resources that then go unused can be even more wasteful than paying on-demand rates.</p>
            </div>

          </div>
        </div>
      </div>
    </section>

    <app-cta-next label="Discover Our Process" next="Process" />

  </div>
</template>
<script>
import AppCtaNext from '@/components/general/CtaNext.vue';

export default {
  components: {
    AppCtaNext,
  },
};
</script>
